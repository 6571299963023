import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Using Python script to track moving objects",
  "path": "/Frequently_Asked_Question/Motion_Tracking_Python_Script/",
  "dateChanged": "2020-05-15",
  "author": "Jörg Dommaschk",
  "excerpt": "Is it possible to control my Full-HD Pan&Tilt camera with a Python script so that it follows moving objects?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Using Python script to track moving objects' dateChanged='2018-03-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='Is it possible to control my Full-HD Pan&Tilt camera with a Python script so that it follows moving objects?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Motion_Blur/' locationFR='/fr/Frequently_Asked_Question/Motion_Blur/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "using-python-script-to-track-moving-objects",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-python-script-to-track-moving-objects",
        "aria-label": "using python script to track moving objects permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using Python script to track moving objects`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Is it possible to control my Full-HD Pan&Tilt camera with a Python script so that it follows moving objects?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: You can use the Python library `}<a parentName="p" {...{
        "href": "https://pypi.org/project/requests/"
      }}>{`Requests`}</a>{` to send cgi commands to trigger a pan or tilt movement to your camera.`}</p>
    <p>{`In the example, we use the face recognition library `}<a parentName="p" {...{
        "href": "https://pypi.org/project/face-recognition/"
      }}>{`Face Recognition`}</a>{` to locate a face in the live image of the camera. Then the camera is panned so that the face is in the centre of the image.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` face_recognition 
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` cv2
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` requests
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` argparse


`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`move`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`direction`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`ip`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`pw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`speed`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#es ist denkbar, bei grossen Abweichungen groessere Geschwindigkeiten zu waehlen um schneller die richtige Position zu finden`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#in diesem einfachen Beispiel verzichten wir darauf`}</span>{`
    r `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` requests`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`get`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{`username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`":"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{`pw`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"@"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{`ip_address`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/ptzctrl.cgi?-step=&-act="`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{`direction`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"&-speed="`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`speed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    r `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` requests`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`get`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{`username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`":"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{`pw`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"@"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{`ip_address`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/ptzctrl.cgi?-step=&-act=stop"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`get_boxes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#'cnn' gibt bessere Ergebnisse, braucht aber eine Grafikkarte. Fuer Berechnungen auf der CPU: model='hog' `}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#der Inhalt dieser Funktion kann ersetzt werden, um andere Objekte zu detektieren, solange Bounding Boxen im Format (top,right,bottom,left) zurueckgegeben werden`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` face_recognition`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`face_locations`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`model`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'cnn'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`


`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` __name__`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"__main__"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    ap `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` argparse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ArgumentParser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    ap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`add_argument`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-ip"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"--IP"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`required`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    ap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`add_argument`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-u"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"--user"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`required`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    ap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`add_argument`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-p"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"--password"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`required`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    ap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`add_argument`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-rtsp"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"--rtsp_port"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`default`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`554`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    ap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`add_argument`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-res"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"--resolution"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`default`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    ap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`add_argument`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-show"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"--show"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`int`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`default`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    args `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`vars`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`parse_args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    username `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"user"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    pw `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"password"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    ip_address `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"IP"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

    show `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`bool`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"show"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Wie viele Pixel Abweichung von der Bildmitte werden toleriert`}</span>{`
    box_tolerance `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`

    capture `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` cv2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`VideoCapture`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"rtsp://"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{`username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`":"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{`pw`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"@"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{`ip_address`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`":"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"rtsp_port"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"resolution"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`while`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        ret`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`frame `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` capture`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`read`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        h`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`c `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`shape
        midX_Soll `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` w`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
        midY_Soll `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` h`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`

        boxes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` get_boxes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` show`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`top`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`right`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`bottom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`left`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` boxes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
                cv2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`rectangle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`left`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`top`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`right`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`bottom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`255`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            cv2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`imshow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            cv2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`waitKey`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`while`}</span>{` boxes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            midX `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`boxes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{`boxes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
            midY `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`boxes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` boxes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`

            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`midX`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`midX_Soll`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`box_tolerance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
                move`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"left"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`ip_address`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`pw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`elif`}</span>{` midX`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`midX_Soll`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`box_tolerance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
                move`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"right"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`ip_address`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`pw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` midY `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` midY_Soll`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`box_tolerance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
                move`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"up"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`ip_address`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`pw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`elif`}</span>{` midY `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` midY_Soll`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` box_tolerance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
                move`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"down"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`ip_address`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`pw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            ret`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`frame `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` capture`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`read`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            boxes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` get_boxes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` show`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`top`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`right`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`bottom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`left`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` boxes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
                    cv2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`rectangle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`left`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`top`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`right`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`bottom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`255`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
                cv2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`imshow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`frame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
                cv2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`waitKey`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      